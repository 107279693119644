<template>
  <div>

    <b-modal
      ref="select-modal"
      hide-footer
    >
      <MembershipsSelectEditor
        :shop_items="shop_items"
        @selected="membership_selected"
        @cancel="()=>{this.$refs['select-modal'].hide();}"
      />
    </b-modal>

    <b-row>
      <b-col cols="6" style="height: 110px;" class="pb-4">
        <b-form-group
        v-if="local_search_options.memberships"
        :label="$t('ADVANCED_SEARCH.MEMBERSHIPS.INCLUDE_INFO')"
        >
          <b-form-select
            v-model="local_search_options.memberships.include"
            :options="include_options"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">

        <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
            @click.prevent="open_select_editor"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('ADVANCED_SEARCH.MEMBERSHIPS.ADD_MEMBERSHIP')}}</a
          >

        </div>

      </b-col>
    </b-row>

    <!-- Table -->
    <div class="table-container">
      <b-table
        id="select-table"
        class="table-striped"
        :fields="fields"
        :items="selected_rows"
        head-variant="light"
        ref="select-table"
      >
      <template #cell(action)="data">
        <div class="text-right">
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="delete_row_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      </b-table>

      <p class="ml-4" v-if="selected_rows.length === 0">{{$t('ADVANCED_SEARCH.MEMBERSHIPS.NONE_SELECTED')}}</p>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import MembershipsSelectEditor from './MembershipsSelectEditor.vue';

import { mapGetters } from 'vuex';

export default {

  name: 'MembershipsSelectTable',

  emits: ['select'],

  props: ['selected', 'search_options'],

  mixins: [ toasts ],

  components: {
    MembershipsSelectEditor
  },

  watch: {
    search_options: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_search_options = { ...this.search_options };
      }
    },

    currentCompanyId() {
      this.load_items();
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId','companies']),

    is_mobile() {
      return is_mobile();
    },

    selected_rows() {
      const rows = [];

      for (const shop_item_id of this.local_search_options.memberships.shop_item_ids) {
        const shop_item = this.shop_items.find(item => item.shop_item_id === shop_item_id);
        rows.push(shop_item);
      }

      return rows;
    }


  },

  methods: {

    open_select_editor() {
      this.$refs['select-modal'].show();
    },

    membership_selected(shop_item_id) {

      this.$refs['select-modal'].hide();

      // check if it already exists

      const found = this.local_search_options.memberships.shop_item_ids.find(item => shop_item_id === item);

      if (found) {
        return;
      }

      this.local_search_options.memberships.shop_item_ids.push(shop_item_id);
    },

    async load_items() {
      try {
        const response = await axios.post(`/shop_item/pagination`, {
          options: {

          },
          orderBy: {
            name: 'asc'
          }
        });

        this.shop_items = response.data;
      }
      catch (err) {
        console.error('error loading memberships', err);
      }
    },

    async edit_row_clicked(item) {

      this.$nextTick(()=>{
        this.$emit('select', item);
      });
    },

    async delete_row_clicked(item) {

      this.local_search_options.memberships.shop_item_ids = this.local_search_options.memberships.shop_item_ids.filter((shop_item_id) => shop_item_id !== item.shop_item_id);

      this.local_search_options.memberships.shop_item_ids = [...this.local_search_options.memberships.shop_item_ids];
    },

  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }

    if (this.search_options) {
      this.local_search_options = { ...this.search_options };
    }

    this.load_items();
  },

  data() {
    return {

      local_search_options: {
        memberships: {
          include: 'ANY', // ANY/ALL
          shop_item_ids: [] // array of ids
        },
      },

      shop_items: [],

      selected_memberships: [],

      include_options: [
        { text: this.$t('ADVANCED_SEARCH.MEMBERSHIPS.INCLUDE_ANY'), value: 'ANY' },
        { text: this.$t('ADVANCED_SEARCH.MEMBERSHIPS.INCLUDE_ALL'), value: 'ALL' },
      ],

      fields: [
        {
          key: 'company.name',
          label: this.$t('ADVANCED_SEARCH.MEMBERSHIPS.COMPANY_NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
        },
        {
          key: 'name',
          label: this.$t('ADVANCED_SEARCH.MEMBERSHIPS.MEMBERSHIP_NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,

    };
  }
};

</script>


<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
